import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';

import { ErrorBoundaryFront } from '~components/index';
import App from './App.tsx';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <ErrorBoundaryFront>
    <App />
  </ErrorBoundaryFront>
);
