import { Colors } from '~constants/index';
import { ICON_SIZES, ICONS } from './CustomIconData';

export type IconName = keyof typeof ICONS;
type IconSizeOption = keyof typeof ICON_SIZES;
interface Props {
  name: IconName;
  size?: IconSizeOption;
  color?: Colors;
  className?: string;
}

const CustomIcon = ({
  name,
  size = 'S',
  color = 'var(--black)',
  className
}: Props) => {
  const IconComponent = ICONS[name] || ICONS.circle;

  return (
    <IconComponent
      className={className}
      size={ICON_SIZES[size]}
      color={color}
    />
  );
};

export default CustomIcon;
