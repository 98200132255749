import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import { ErrorBoundaryFront } from '~components/index';
import { ROUTES } from '~constants/index';
import { useLocalStorage } from '~hooks/index';
import type { Module } from '~types/CommonTypes';
import ModuleWrapper from './ModuleWrapper/ModuleWrapper';

interface Props extends React.PropsWithChildren {
  module: Module;
}

const PrivateRoute = ({ module, children }: Props) => {
  const { userData, recaptcha, removeRecaptcha } = useLocalStorage();

  useEffect(() => {
    if (recaptcha) removeRecaptcha();
  }, []);

  return (
    <>
      {userData ? (
        <ErrorBoundaryFront>
          <ModuleWrapper data={module}>{children}</ModuleWrapper>
        </ErrorBoundaryFront>
      ) : (
        <Navigate to={ROUTES.ROOT} />
      )}
    </>
  );
};

export default PrivateRoute;
