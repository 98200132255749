import { OverlayTrigger, Popover } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import CustomIcon from '~components/common/CustomIcon/CustomIcon';

interface Props {
  name: string;
  text?: string;
  required: boolean;
  helper: string | null;
}

const InputLabel = ({ name, text, required, helper }: Props) => {
  const { t } = useTranslation();

  return (
    <label htmlFor={name} className="fw-semibold">
      <div className="d-flex gap-2">
        <div>
          {text && t(text)}
          {required && (
            <span className="customInput_required ms-1 text-wrap">*</span>
          )}
        </div>
        {helper && (
          <OverlayTrigger
            key="auto"
            placement="auto"
            overlay={
              <Popover id="popover-basic">
                <Popover.Body>
                  <p>{helper}</p>
                </Popover.Body>
              </Popover>
            }
          >
            <div className="cursor-pointer">
              <CustomIcon name="questionCircle" />
            </div>
          </OverlayTrigger>
        )}
      </div>
    </label>
  );
};

export default InputLabel;
