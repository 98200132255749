import { memo } from 'react';
import { Button as BtnBootstrap, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import CustomIcon, { type IconName } from '../CustomIcon/CustomIcon';
import LinkButtonWrapper from './LinkButtonWrapper';
import './button.scss';

export type ButtonProps = Partial<{
  type: 'button' | 'submit';
  variant: 'primary' | 'secondary' | 'danger' | 'link';
  border: 'full' | 'none';
  iconName: IconName;
  text: string;
  title: string;
  disabled: boolean;
  fullWidth: boolean;
  isDoingRequest: boolean;
  fileToDownload: string;
  onClick: () => void;
  isHidden: boolean;
}>;

const Button = ({
  type = 'button',
  variant = 'primary',
  border = 'full',
  title = '',
  text = '',
  iconName,
  disabled = false,
  fullWidth = false,
  isDoingRequest = false,
  fileToDownload,
  onClick
}: ButtonProps) => {
  const { t } = useTranslation();
  const borderSelected = {
    full: 'btn_border_full',
    none: 'btn_border_none'
  };
  const props = {
    type,
    disabled: disabled || isDoingRequest,
    onClick
  };

  return (
    <LinkButtonWrapper link={fileToDownload} disabled={props.disabled}>
      <div title={t(title)} className={!fullWidth ? 'btn_fit_container' : ''}>
        <BtnBootstrap
          aria-label="button"
          {...props}
          className={`${fullWidth ? 'w-100' : 'w-auto'} ${
            borderSelected[border]
          } btn_${variant} px-4 py-2`}
        >
          {iconName && <CustomIcon name={iconName} />}
          {text && t(text)}
          {isDoingRequest && (
            <Spinner role="button_spinner" animation="border" size="sm" />
          )}
        </BtnBootstrap>
      </div>
    </LinkButtonWrapper>
  );
};

const MemoizeButton = memo(Button);
export default MemoizeButton;
