import { useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import Icon401 from '~assets/401.svg';
import { Button } from '~components/index';
import { useAppStorage } from '~store/useStore';
import './errorBoundaryBack.scss';

const ErrorBoundaryBack = ({ children }: React.PropsWithChildren) => {
  const { t } = useTranslation();

  const { errorRequest, cleanError } = useAppStorage();
  const [tokenInvalid, setTokenInvalid] = useState(false);

  useEffect(() => {
    if (errorRequest) {
      const errorInfo = (type: string) =>
        (errorRequest as Record<string, string>)?.[type] ||
        (errorRequest.data as Record<string, string>)?.[type];

      const isTokenNotValid = errorInfo('code') === 'token_not_valid';

      if (isTokenNotValid) setTokenInvalid(true);
      else {
        setTokenInvalid(false);
        cleanError();
      }
    }
  }, [errorRequest]);

  const clearAppStorage = () => {
    cleanError();
    localStorage.clear();
    window.location.replace('/');
  };

  return (
    <>
      {tokenInvalid ? (
        <div className="tokenInvalid text-center">
          <div className="tokenInvalid_container">
            <Image src={Icon401} alt="img_401" width="25%" fluid />
            <div className="my-4">
              <h1 className="fw-bold">
                {t('components.errorBoundary_back_title')}
              </h1>
              <p>{t('components.errorBoundary_back_message')}</p>
            </div>
            <Button text="btns.login" onClick={clearAppStorage} fullWidth />
          </div>
        </div>
      ) : (
        children
      )}
    </>
  );
};

export default ErrorBoundaryBack;
