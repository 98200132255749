import { AiOutlineInfoCircle } from 'react-icons/ai';
import { BiLogOut } from 'react-icons/bi';
import { BsTrashFill } from 'react-icons/bs';
import {
  FaChevronDown,
  FaChevronLeft,
  FaChevronRight,
  FaChevronUp,
  FaCircle,
  FaExchangeAlt,
  FaEye,
  FaFilter,
  FaPencilAlt,
  FaQuestion,
  FaRegQuestionCircle,
  FaUser
} from 'react-icons/fa';
import {
  FaFileArrowUp,
  FaMagnifyingGlass,
  FaPlus,
  FaUserDoctor
} from 'react-icons/fa6';
import { GrUserManager } from 'react-icons/gr';
import { HiOutlineDocumentReport } from 'react-icons/hi';
import { IoLanguage, IoReload } from 'react-icons/io5';
import { MdNewReleases } from 'react-icons/md';
import { SiPowerbi } from 'react-icons/si';

export const ICONS = {
  chevronDown: FaChevronDown,
  chevronLeft: FaChevronLeft,
  chevronRight: FaChevronRight,
  chevronUp: FaChevronUp,
  circle: FaCircle,
  eye: FaEye,
  exchange: FaExchangeAlt,
  fileArrowUp: FaFileArrowUp,
  filter: FaFilter,
  infoCircle: AiOutlineInfoCircle,
  language: IoLanguage,
  logout: BiLogOut,
  magnifyingGlass: FaMagnifyingGlass,
  pencil: FaPencilAlt,
  plus: FaPlus,
  powerBi: SiPowerbi,
  question: FaQuestion,
  releases: MdNewReleases,
  reload: IoReload,
  report: HiOutlineDocumentReport,
  trashFill: BsTrashFill,
  questionCircle: FaRegQuestionCircle,
  user: FaUser,
  userDoctor: FaUserDoctor,
  userManager: GrUserManager
} as const;

export const ICON_SIZES = {
  XS: 14,
  S: 16,
  MD: 24,
  LG: 32,
  XL: 50
} as const;
