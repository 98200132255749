import type { Location } from 'react-router-dom';

import { Accordion, CustomIcon } from '~components/index';
import SidebarItem from '~layouts/UserAuthenticated/Sidebar/SidebarItem';
import type { SidebarData } from '~layouts/UserAuthenticated/Sidebar/SidebarTypes';

//* Sidebar items render
const sidebarInfoHandler = (
  item: SidebarData,
  location: Location,
  level: number
) => {
  if (item.children.length === 0) {
    return (
      <div key={item.id} className={level > 0 ? 'sidebar_identation' : ''}>
        <SidebarItem data={item} location={location} />
      </div>
    );
  } else {
    return (
      <div
        key={`sidebar_parent_${item.id}`}
        className={level > 0 ? 'sidebar_identation' : ''}
      >
        <Accordion
          data={[item]}
          header={(item) => {
            return (
              <div className="d-flex align-items-center">
                <CustomIcon name={item.icon} />
                <p className="text-break ms-2">{item.label}</p>
              </div>
            );
          }}
          body={({ children }) => (
            <div className="d-flex flex-column gap-1 mt-1">
              {children.map((child) =>
                sidebarInfoHandler(child, location, level + 1)
              )}
            </div>
          )}
          type="light"
          isSidebar
        />
      </div>
    );
  }
};
export const sidebarItemsRender = (data: SidebarData[], location: Location) => (
  <div className="sidebar_items my-2">
    {data.map((item) => sidebarInfoHandler(item, location, 0))}
  </div>
);

//* Private route configuration
const privateRouteItemHandler = (item: SidebarData) => {
  if (item.children.length === 0) return item;
  else return item.children.map((child) => child).flat();
};
export const privateRouteOptions = (data: SidebarData[] = []) => {
  return data
    .map((item) => {
      if (item.children.length === 0) return item;
      else
        return item.children
          .map((child) => privateRouteItemHandler(child))
          .flat();
    })
    .flat();
};
