import { memo } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { Button, ButtonList } from '~components/index';
import type { GeneralModalProps } from '../ModalTypes';
import '../modals.scss';

interface Props extends GeneralModalProps {
  body: React.ReactNode;
}

const BasicModal = ({
  title,
  titleParams = {},
  body,
  extraButtons = [],
  onHide = () => {},
  size = 'lg',
  fullscreen
}: Props) => {
  const { t } = useTranslation();

  return (
    <Modal
      show
      onHide={onHide}
      backdrop="static"
      keyboard={false}
      size={size}
      fullscreen={fullscreen}
      scrollable
    >
      <Modal.Header closeButton>
        <h4>{t(title, titleParams)}</h4>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column gap-2 px-3">{body}</div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" text="btns.close" onClick={onHide} />
        <ButtonList data={extraButtons} />
      </Modal.Footer>
    </Modal>
  );
};

const MemoizeModal = memo(BasicModal);
export default MemoizeModal;
