import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Navigate } from 'react-router-dom';

import { ErrorBoundaryFront } from '~components/index';
import { ROUTES } from '~constants/index';
import { useLocalStorage } from '~hooks/index';

const PublicRoute = ({ children }: React.PropsWithChildren) => {
  const { userData, recaptcha, setRecaptcha } = useLocalStorage();

  return (
    <>
      {Object.keys(userData).length > 0 ? (
        <Navigate to={ROUTES.PLATFORM} />
      ) : (
        <ErrorBoundaryFront>
          <GoogleReCaptcha
            onVerify={(token) => {
              if (!recaptcha) setRecaptcha(token);
            }}
          />
          {children}
        </ErrorBoundaryFront>
      )}
    </>
  );
};

export default PublicRoute;
