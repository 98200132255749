import i18next from 'i18next';

import en from './options/en.json';
import es from './options/es.json';

const resources = {
  en: { allTranslations: en },
  es: { allTranslations: es }
};

i18next.init({
  interpolation: { escapeValue: false },
  lng: 'es',
  resources,
  ns: ['allTranslations']
});

export const tnl = i18next.t;
export default i18next;
