import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { CustomIcon } from '~components/index';
import type { Module } from '~types/CommonTypes';
import './moduleWrapper.scss';

interface Props extends React.PropsWithChildren {
  data: Module;
}

const ModuleWrapper = ({ data, children }: Props) => {
  const { t } = useTranslation();
  const { title, breadcrumbs } = data;

  return (
    <div className="moduleWrapper">
      <div className="moduleWrapper_titleContainer">
        <h2 className="title">{t(title)}</h2>
        {breadcrumbs && (
          <div className="d-flex gap-1">
            {breadcrumbs.map((breadcrumb, index) => (
              <div
                className="d-flex align-items-center"
                key={`breadcrumb_${index}`}
              >
                <Link to={breadcrumb.route} state={{}}>
                  {t(breadcrumb.label)}
                </Link>
                <CustomIcon name="chevronRight" color="var(--gray)" />
              </div>
            ))}
            <p>{t(title)}</p>
          </div>
        )}
      </div>
      <div className="moduleWrapper_childrenContainer">{children}</div>
    </div>
  );
};

export default ModuleWrapper;
